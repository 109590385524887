require('./bootstrap');
window.moment = require('moment')
import Vue from 'vue'
window.Vue = require('vue');

// import datePicker from 'vue-bootstrap-datetimepicker';
// import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
// Vue.use(datePicker);

import Datepicker from 'vuejs-datepicker';
import {en, hu, de} from 'vuejs-datepicker/dist/locale'
Vue.component('Datepicker', Datepicker)
